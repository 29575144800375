<template>
    <div>
        <div>诊单编号：{{kid}}</div>
        <div>疾病类别：{{dismain.Dis_Name}}</div>
        <div>状态：{{state}}</div>
        <div class="doc-del" v-show="success&&state==9">
            <p style="font-size: 35px;">问诊单已删除</p>
            <p>请联系在线医助</p>
        </div>
        <div class="list-content" v-if="list.length>0">
            <div v-for="(item, index) in list" :key="index">
                <div v-if="item.KFiel_Type==0">
                    <h3>{{item.KFiel_ShowCaption}}</h3>
                </div>
                <div class="inputmain" v-if="item.KFiel_Type==1">
                    <p class="label">{{item.KFiel_ShowCaption}}：</p>
                    <el-input class="labelinput" type="text" v-model="item.Values" :label="item.KFiel_ShowCaption+'：'"
                        :placeholder="'请输入'+item.KFiel_ShowCaption" :rules="[{ required: true, message: '请填写用户名' }]">
                    </el-input>
                </div>
                <div class="inputmain" v-if="item.KFiel_Type==2">
                    <p class="label">{{item.KFiel_ShowCaption}}：</p>
                    <el-input class="labelinput" v-model="item.Values" :label="item.KFiel_ShowCaption+'：'"
                        type="textarea" rows='3' :placeholder="'请输入'+item.KFiel_ShowCaption"
                        :rules="[{ required: true, message: '请填写用户名' }]">
                    </el-input>
                </div>
                <div class="inputmain" v-if="item.KFiel_Type==5||item.KFiel_Type==7">
                    <div style="width: 100%;box-sizing: border-box;">
                        <div class="doc-title">{{item.KFiel_ShowCaption+'：'}}</div>
                        <div class="doc-list">
                            <el-radio-group v-model="item.Values">
                                <el-radio v-for="(it, idx) in item.checklist" @change="check2(it)" :key="idx"
                                    :label="it.KF_Values" style="margin-bottom: 10px;">{{it.KF_Values}}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
                <div class="inputmain" v-if="item.KFiel_Type==6">
                    <div style="width: 100%;box-sizing: border-box;">
                        <div class="doc-title">{{item.KFiel_ShowCaption+'：'}}</div>
                        <div class="doc-list">
                            <el-checkbox-group v-model="item.list">
                                <el-checkbox v-for="(it, idx) in item.checklist" @change="check(item.list,item)"
                                    :key="idx" shape="square" :label="it.KF_Values" style="margin-bottom: 10px;">
                                    {{it.KF_Values}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <div v-if="item.KFiel_Type==8">
                    <h5>{{item.KFiel_ShowCaption}}</h5>
                </div>
            </div>
        </div>
        <div v-if="list2.length>0">
            <div class="box">
                <div style="padding-top:10px;"></div>
                <div class="list-label" style="margin:10px;">问诊详情</div>
                <div class="doc-item flex-row">
                    <div class="doc-key">
                        <p>问诊编号</p>
                    </div>
                    <div class="doc-value">
                        <p>{{kid}}</p>
                    </div>
                </div>
                <div class="doc-item flex-row" v-for="(item, index) in list2" :key="index">
                    <div class="doc-key">
                        <p>{{item.KFiel_ShowCaption}}：</p>
                    </div>
                    <div class="doc-value">
                        <p>{{item.KFValues}}</p>
                    </div>
                </div>
                <div class="half-slot"></div>
            </div>
        </div>
        <div class="box" v-show="success&&(photo.length>0||state==0)">
            <div class="box-container">
                <div class="list-label">化验单<span
                        style="margin-left: 10px;color:#A9A9A9;font-size: 14px;">(仅供医生查看)</span></div>
                <div class="img-box">
                    <div class="item" v-for="(item, index) in photo" :key="index">
                        <el-image :src="photoFilter(item)" alt="" :preview-src-list="photoFilter2(item)"></el-image>
                        <div class="close-btn" @click="removeImg(index)">
                            <i class="el-icon-close" color="#fff" size="20px" />
                        </div>
                    </div>
                    <div class="item" v-if="showBtn">
                        <el-upload class="avatar-uploader" action="    " :show-file-list="false"
                            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                            :http-request="upload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                    <br style="clear: both;">
                </div>
            </div>
        </div>
        <div class="box" v-if="state==6">
            <div style="padding-top:10px;"></div>
            <div class="list-label" style="margin:10px">解决方案</div>
            <div style="padding: 10px;box-sizing: border-box;">
                <el-divider content-position="left" v-if="YDFA!=''">治疗方案</el-divider>
                <p class="note">{{YDFA}}</p>
                <el-divider content-position="left" v-if="YSTZFA!=''">饮食运动方案</el-divider>
                <p class="note">{{YSTZFA}}</p>
                <el-divider content-position="left" v-if="BZ!=''">指导意见</el-divider>
                <p class="note">{{BZ}}</p>
            </div>
        </div>
        <div style="margin: 16px;" v-show="showBtn">
            <el-button round block type="primary" native-type="submit" @click="save()">
                提交
            </el-button>
        </div>
        <div class="slot"></div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                kid: '',
                state: 0,
                dismain: '',
                success: false,
                showBtn: false,
                show: false,
                list: [],
                select_items: [],
                KFiel_ID: "",
                desc: '',
                photo: [],
                list2: [],
                YSTZFA: "",
                YDFA: "",
                BZ: "",
                imageUrl: ''
            }
        },
        mounted() {
            this.kid = this.$route.query.id;
            this.axios.post('/field/get_z_field_detail?kid=' + this.kid+"&input_id=" + sessionStorage.getItem("servicer_id"))
                .then(res => {
                    if (res.data.code == '0') {
                        this.dismain = res.data.dismain;
                        if (res.data.dismain.State == 9) {
                            this.state = 9;
                            this.success = true;
                            return
                        }
                        var photo = res.data.photo == null ? [] : res.data.photo;
                        var fiels = res.data.fiels == null ? [] : res.data.fiels;
                        this.photo = photo.map(item => item.FileName);
                        this.state = res.data.dismain.State;
                        for (let i = 0; i < res.data.result.length; i++) {
                            var item = res.data.result[i];
                            item.Values = "";
                            item.Values = "";
                            if (item.checklist) {
                                item.list = [];
                            }
                            for (let index = 0; index < fiels.length; index++) {
                                var element = fiels[index];
                                if (element.KFiel_ID == item.KFiel_ID) {
                                    item.Values = element.KFValues;
                                    if (item.checklist) {
                                        item.list = element.KFValues.split(';');
                                    }
                                    continue;;
                                }
                            }
                            this.list.push(item);
                        }
                        this.list = this.list.sort((a, b) => {
                            return a.KOrder - b.KOrder;
                        });
                        this.$nextTick(() => {
                            this.showBtn = true;
                            this.success = true;
                        })
                        if (this.state == 6) {
                            if (res.data.fa != null) {
                                this.YSTZFA = res.data.fa.YSTZFA == null ? "" : res.data.fa.YSTZFA;
                                this.YDFA = res.data.fa.YDFA == null ? "" : res.data.fa.YDFA;
                                this.BZ = res.data.fa.BZ == null ? "" : res.data.fa.BZ;
                            }
                        }
                    } else { }
                })
                .catch(res => {
                    console.log(res);
                })
        },
        methods: {
            removeImg(index) {
                this.photo.splice(index, 1);
            },
            check(list, item) {
                item.Values = "";
                item.Values = list.join(";");
            },
            check2(item) {
                item.Values = item.KF_Values;
            },
            photoFilter(src) {
                var url = 'http://112.250.105.14:10087/public/' + src;
                return url;
            },
            photoFilter2(src) {
                var url = 'http://112.250.105.14:10087/public/' + src;
                var arr = [];
                arr.push(url);
                return arr;
            },
            upload(content) {
                let formData = new FormData();
                formData.append("file", content.file); // 'file[]' 代表数组 其中`file`是可变的
                this.axios
                    .post("http://112.250.105.14:10087/qiniu/examples/upload_simple_file3.php", formData)
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.photo.push(rs.data.file);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过 20MB!");
                }
                return isLt2M;
            },
            save() {
                var list = this.list.map(item => {
                    var obj = new Object();
                    obj.KFiel_ID = item.KFiel_ID;
                    obj.KFiel_Type = item.KFiel_Type;
                    obj.KFiel_ShowCaption = item.KFiel_ShowCaption;
                    obj.KDis_ID = item.KDis_ID;
                    obj.Values = item.Values;
                    return obj;
                }).filter(item => item.Values != null && item.Values != '');
                this.$confirm('此操作将对用户填写的表单进行修改, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log({ id: this.kid, list: JSON.stringify(list), photo: JSON.stringify(this.photo) })
                    this.axios.post('/chat/insert_z_field_disrecord', this.qs.stringify({ id: this.kid, list: JSON.stringify(list), photo: JSON.stringify(this.photo) }))
                        .then(res => {
                            if (res.data.code == '0') {
                                this.$message({
                                    message: '已提交',
                                    type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: "保存失败," + res.data.msg,
                                    type: 'success'
                                });
                            }
                        })
                        .catch(res => {
                            console.log(res)
                        })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消删除'
                    });
                });
            }
        },
    }
</script>
<style>
    .list-content {
        padding: 0 10px;
    }

    .list-content h3 {
        color: rgb(73, 162, 251);
        font-size: 18px;
    }

    .van-field__label {
        width: 8em;
    }

    .doc-title {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;
        width: 15%;
        float: left;
        text-align: right;
    }

    .doc-list {
        border: 1px solid #d0d0d0;
        border-radius: 5px;
        padding: 15px 20px;
        width: 85%;
        float: left;
        box-sizing: border-box;
        text-align: left;
    }

    .loading {
        margin-top: 30%;
    }

    .add-pic {
        height: 80px;
        width: 80px;
        display: inline-block;
        border: 2px dotted #808080;
        text-align: center;
        line-height: 80px;
        font-size: 25px;
        color: #808080;
    }

    .img-box {
        width: 100%;
        box-sizing: border-box;
    }

    .img-box .item {
        width: 200px;
        box-sizing: border-box;
        padding: 10px;
        float: left;
        margin-bottom: 10px;
        height: 200px;
        position: relative;
    }

    .img-box span {
        width: 100%;
        box-sizing: border-box;
        height: 80px;
    }

    .img-box img {
        width: 100%;
        box-sizing: border-box;
        object-fit: contain;
        height: 100%;
    }

    body {
        background-color: rgb(248, 248, 250);
    }

    .doc-item {
        margin: 0 auto;
        border: 1px solid #ebedf0;
        margin-bottom: -1px;
        width: 100%;
        box-sizing: border-box;
        float: left;
    }

    .doc-key {
        width: 40%;
        border-right: 1px solid #ebedf0;
        float: left;
        box-sizing: border-box;
    }

    .doc-key p {
        padding: 10px;
        color: #808080
    }

    .doc-value {
        width: 60%;
        padding-left: 15px;
        float: left;
        box-sizing: border-box;
    }

    .doc-value p {
        padding: 10px;
        color: #808080
    }

    .doc-del {
        color: #A9A9A9;
        font-size: 25px;
        margin-top: 30%;
        text-align: center;
    }

    .doc-del p {
        line-height: 3;
    }

    .close-btn {
        position: absolute;
        right: 5px;
        top: 5px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: rgba(140, 140, 140, 0.75);
        text-align: center;
        line-height: 20px;
        color: #fff;
        cursor: pointer;
        z-index: 100;
    }

    .label {
        width: 15%;
        float: left;
        text-align: right;
        font-weight: 600;
    }

    .labelinput {
        width: 85%;
        float: left;
    }

    .inputmain {
        width: 100%;
        float: left;
        margin-bottom: 10px;
    }

    .el-input__inner {
        border-color: #d0d0d0;
    }

    .avatar-uploader {
        float: left;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>